@import '../../../components/sass/theme.module.scss';

.modal {

  &-container {
    display: flex;
    z-index: 1020;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    height: calc(100vh);
    width: 100vw;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
  }

  &-content {
    z-index:1030;
    position: fixed;
    background-color: white;
    overflow-y: scroll;
    padding: 2rem;
    border-radius: 3px;
    box-shadow: 6px 3px 5rem $darkGray;
    // top: 40vh;
    height: fit-content;
    // left: 30vw;
    width: fit-content;
    min-width: 27rem;
    max-width: 38rem;
    
    & > p {
      text-align: center;
    }
    
    & > .button-options {
      display: flex;
  
    }
  }

  &-buttontext-fixing {
    text-transform: none;
    margin-left: 2rem;
  }
}

.top-orange-border {
  border-top: 3px solid $invalidCardOrange;
}

.simple {
  &-body {
    display: flex;
    flex-direction: row;

    & > img {
      width: 1rem;
      padding-right: 1rem;
      align-self: start;
    }
  }
  &-text {
    & > h3, & > p {
      font-size: 1rem;
      color: black;
      margin: 0;
      padding: 0;
    }

    & > h3 {
      font-weight: 700;
    }
    & > p {
      padding-top: 1rem;
    }

  }
}

.withdata {
  &-text {
    & > h3 {
      color: black;
      padding: 0;
      margin: 0;
      font-weight: 700;
      font-size: 1.5rem;
    }
  }
}

.number-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 0.5rem 0;

  & > * {
    color: black;
    padding: 0;
    margin: 0;
    font-weight: 700;
  }

  & > h4 {
    font-size: 1.5rem;
    padding-right: 1rem;

  }

  & > p {
    font-size: 1rem;
  }
}

.labeled {
  padding: 0.7rem 0rem;

  & > * {
    font-weight: 700;
    margin: 0;
    padding: 0;
    font-size: 1rem;
  }

  & > h4 {
    color: black;
  }

  & > p {
    color: $primaryBlue;
  }
}

.customcharge {
  &-input-container {
    display: flex;
    flex-direction: column;
    position: relative;

    & > input[type="number"] {
      width: 12rem;
    } 
  }

  &-input-dollar {
    position: absolute;
    top: 11px;
    left: -15px;
  }
  
  &-info-text {
    font-size: 0.8rem;
    color: $darkGray;
    margin: 0;
    padding: 0;
  }

  &-radio {
    &-container {
      display: flex;
      justify-content: space-between;
      margin-bottom: 0.8rem;
    }
    &-buttons {
      accent-color: $primaryBlue;
      margin: 0;
      margin-right: 0.5rem;
    }
  }
}

.selector {

  &-grid {
    padding: 1rem;
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-template-rows: 1fr 1fr;
    gap: 5px;

    & > p {
      margin: 0;
      padding: 0;
      align-self: center;
    }
  }

  &-container {
    display: flex;
    justify-content: space-evenly;
  }

  &-active, &-inactive {
    cursor: pointer;
    border: 2px solid $secondaryMediumDarkBlue;
    border-radius: 3px;
    font-weight: 700;
    padding: 0 8px;
  }

  &-active {
    background-color: $secondaryDarkBlue;
    color: white;
  }
  &-inactive {
    background-color: transparent;
    color: $secondaryMediumDarkBlue;
  }

  &-newtime {
    text-align: center;
  }
}