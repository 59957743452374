@import '../../components/sass/theme.module.scss';

.bid-container {
  padding: 0.5rem 0;
  width: calc(100vw - 2.5rem);
  margin: 2rem auto 0;
  background-color: white;
  border-radius: 3px;
  max-width: 600px;

  & > p {
    padding: 0 1rem;
  }
}

.bid-text, .bid-text-large {
  font-weight: 700;
  font-size: 0.8rem;
  margin: 0;

  &-large {
    font-size: 1.2rem;
  }
}

.bid-selectors {
  display: flex;
  justify-content: space-evenly;
}

.bid-value-active, .bid-value-inactive {
  cursor: pointer;
  border: 2px solid $secondaryMediumDarkBlue;
  margin-top: 1rem;
  border-radius: 3px;
  font-weight: 700;
  padding: 0 8px;
}

.bid-value-active {
  background-color: $secondaryDarkBlue;
  color: white;
}

.bid-value-inactive {
  background-color: transparent;
  color: $secondaryMediumDarkBlue;
}

.bid-input {
  font-size: 0.8rem;
}

p.high-bid-text {
    color:$primaryBlue;
}

.hidden {
  display: none;
}

.high-bid-container {
  margin: 1rem;
}

.bid-text-input {
  font-size: 0.8rem;
  margin: 0;
}

.high-bid-notification {
  color: $primaryBlue;
  font-size: 0.8rem;
  font-weight: 700;
  border: 2px solid $primaryBlue;
  width: fit-content;
  border-radius: 2rem;
  padding: 0rem 0.3rem;
}

.outbid-notification {
  color: $darkGold;
  font-size: 0.8rem;
  font-weight: 700;
  border: 2px solid $darkGold;
  width: fit-content;
  border-radius: 2rem;
  padding: 0rem 0.3rem;
}

// below stylings make the currently high bidder banner go on the same line as the current bid
.high-bid-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bid-input {
  margin: 0 1rem;
  width: calc(100% - 2.5rem);
}

.bid-info-box {
  margin-top: 0.6rem;

  & > p {
    margin: 0;
    font-size: 0.8rem;
    font-weight: 700;
    color: $darkGray;
  }
}

.bid-title {
  color: $primaryBlue;
  font-weight: 700;
  margin: 0.3rem 0;
  font-size: 1.3rem;
  text-align: center;
}

.bid-selectors {
  padding: 0 1rem;
  justify-content: space-evenly;
}

.place-bid-button {
  margin: 1rem;
  width: calc(100% - 2rem);
}

// @media #{$phone} {

// }