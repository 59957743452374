@import '../../../components/sass/theme.module.scss';


.closed-auction {
  &-summary{
    display: flex;
    justify-content: space-around;
    font-weight: 700;
  }
  &-charge-selected{
    border: 1px solid black;
    border-radius: 3px;
    width: fit-content;
    padding: 1rem;
    & > p {
      font-weight: 700;
      padding: 0 0 0 1rem;
      margin: 0;
    }
    & > button {
      margin: 1rem;
    }
  }

  &-info {
    text-align: center;
    font-weight: 700;
    margin-top: 2rem;
  }

  &-charge-note {
    font-size:0.9rem;
  }

  &-button-label {
    font-size:0.9rem;
  }

  &-attendee-filters{
    display: flex;
    margin: 1rem;
    align-items: flex-end;
    justify-content: space-between;

  }
  &-filter{
    width: 17rem;
    & > input, & > select {
      width: 12rem;
      height: 1.6rem;
    }

  }

  //attendee table
  &-table {
    margin: 2rem 0;
    font-size: 0.8rem;
    padding: 0;

    & > table {
      border-spacing: 0;
      border: 2px solid black;
      border-radius: 3px;
      border-bottom: 0px solid transparent;
    }

    &-header {
      background-color: $borderGray;

      & > th {
        border-bottom: 2px solid black;
        padding: 0 1rem;
      }
    }
    
    &-footer {
      background-color: $buttonActiveBlue;
      color: black;
      font-weight: 700;
      text-align: center;

      & > td {
        border-bottom: 2px solid black;
      }
    }
    
    &-data-row {
      text-align: center;

      & > td {
        border-bottom: 2px solid black;
      }
    }
  }

  &-attendee-heading {
    // display: flex;
    // align-items: center;
    // justify-content: space-between;
    padding: 1rem 2rem 0;

    & > p {
      font-weight: 700;
      margin: 0;
    }
    & > h2 {
      color: black;
      font-size: 1.5rem;
      font-weight: 700;
      margin: 0;
    }

  }

  &-unclaimed {
    margin: 2rem;
    
    & > h4 {
      font-weight: 700;
      margin: 0;
      padding: 0;
    }

    & > ul {
      margin: 0;
      padding: 0;
      list-style: none;
    }
  }

  &-item-actions {
    padding: 0.5rem;

    & > * {
      color: $primaryBlue;
      font-weight: 700;
      padding: 0;
      margin: 0;
      border-radius: 3px;
    }

    & > *:hover {
      background-color: $borderGray;
    }
  }

}

.action-link {
  cursor:pointer;
}

.payment-reminder-button {
  margin-left: 1rem;
    max-width: 17rem;
    text-wrap: auto;
    height: fit-content;
    padding: 0.5rem;
}

.back-to-attendee-link{
  font-weight: 700;
  color: $primaryBlue;
  margin-bottom: 1rem;

  &:hover {
    color: $secondaryMediumDarkBlue;
    cursor: pointer;
  }
}


@media #{$phone} {
  

}