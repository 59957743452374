@import '../../components/sass/theme.module.scss';

.page-container {
  margin: 1rem;
}

.edit-container {
  // padding: 0 1rem 1rem;
  width: calc(100% - 2rem);
  margin: 0 1rem;

  & > form > h2 {
    margin: 0;
  }
}

.asterisk {
  color: $messagingRed;
  font-size: 1rem;
  font-weight: 700;
}

.item-container {
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  max-width: 30rem;
}

.error-text {
  color: $messagingRed;
  font-size: 0.8rem;
  font-weight: 700;
  padding-left: 1rem;
}

.acceptable-text {
  font-size: 0.8rem;
  color: $darkGray;
}

.create-item-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 1.5rem;

  &-checkbox {
    & > p {
      font-size: 0.8rem;
      color: $darkGray;
      padding: 0;
      margin: 0;
    }
  }
}

.edit-item-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  font-size: 0.75rem;
}

.success-container {
  margin: 1rem;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > p {
    padding-left: 2rem;
  }
}
.success-checkmark {
  width: 40px;
}

.label-notice {
  color: $messagingRed;
  font-size: 0.7rem;
}

.thumbnail-container {
  display: flex;
  & > img {
    width: 100px;
  }
}

.button-image-edit {
  margin: 1rem;
}

input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Hide arrows in number input fields for Firefox */
input[type='number'] {
  -moz-appearance: textfield;
  appearance: textfield;
}

@media #{$phone} {
  
  .item-container {
    margin-left: 0;
  }
}